import { TableStateSorting } from '@insights/services';
import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TableRowProps,
  Tooltip,
  Typography
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import _ from 'lodash';
import {
  MRT_Cell,
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowData,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_Updater,
  useMaterialReactTable
} from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { ReactNode, useMemo } from 'react';

function withCloseMenu(action: () => void, closeMenu: () => void): () => void {
  return () => {
    closeMenu();
    action();
  };
}

// This is only a normalization of a pattern: exposing the searchable data in accessorFn
// but not using renderedCellValue in the Cell component. Using this function is cleaner
// than building a very long string in the component.
export function accessorForSearch(values: (string | undefined)[]): string {
  return _.compact(values).join(' ');
}

export interface TopActionProps<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}

export interface RowActionMenuProps<TData extends MRT_RowData> {
  row: MRT_Row<TData>;
}

export interface RowActionButtonsProps<TData extends MRT_RowData> {
  cell: MRT_Cell<TData>;
  row: MRT_Row<TData>;
  staticRowIndex?: number;
  table: MRT_TableInstance<TData>;
}

export interface ActionButtonInfo {
  icon: () => ReactNode;
  tooltip: string;
  onClick: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  ml?: number;
  mr?: number;
}

export interface ActionMenuItemInfo {
  icon?: () => ReactNode;
  title: string;
  endAdornment?: () => ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
}

export interface DynamicTableRowProps<TData extends MRT_RowData> {
  isDetailPanel?: boolean;
  row: MRT_Row<TData>;
  staticRowIndex: number;
  table: MRT_TableInstance<TData>;
}

export interface SelectionProps<TData extends MRT_RowData> {
  getRowId: (originalRow: TData, index: number, parentRow: MRT_Row<TData>) => string;
  selectedRowIds: string[];
  onSelectionChanged: (ids: string[]) => void;
  canMultiSelect?: boolean;
}

export interface InsightsTableOptions<TData extends MRT_RowData> {
  getTopActionButtons?: (props: TopActionProps<TData>) => (ActionButtonInfo | undefined | false)[];
  renderCustomTopActions?: (props: TopActionProps<TData>) => ReactNode;
  getRowActionMenuItems?: (props: RowActionMenuProps<TData>) => (ActionMenuItemInfo | undefined | false)[];
  getRowActionButtons?: (props: RowActionButtonsProps<TData>) => (ActionButtonInfo | undefined | false)[];
  rowProps?: (props: DynamicTableRowProps<TData>) => TableRowProps;
  defaultSorting?: TableStateSorting;
  disablePagination?: boolean;
  disableGlobalFilter?: boolean;
  selectionOptions?: SelectionProps<TData>;
  defaultPageSize?: number;
  getSubRows?: (originalRow: TData) => TData[];
  fontSize?: 'small' | 'normal';
}

export function useInsightsTable<TData extends MRT_RowData>(
  stateKey: string,
  data: TData[],
  title: string | ((table: MRT_TableInstance<TData>) => ReactNode),
  getColumns: () => (MRT_ColumnDef<TData> | undefined | false)[],
  options?: InsightsTableOptions<TData>
): MRT_TableInstance<TData> {
  const { localizationService, settingsStore } = useInsightsServices();
  const {
    getTopActionButtons,
    renderCustomTopActions,
    getRowActionMenuItems,
    getRowActionButtons,
    rowProps,
    defaultSorting,
    disablePagination = false,
    disableGlobalFilter = false,
    selectionOptions,
    defaultPageSize,
    getSubRows,
    fontSize = 'normal'
  } = options ?? {};

  // Safeguard (did not want to use combined type)
  if (getRowActionMenuItems != null && getRowActionButtons != null) {
    throw new Error('Cannot support both an action menu and action buttons');
  }
  // getTopActionButtons and renderTopActions can both be used. They're
  // rendered in that order, to the right of the search field.

  const tablePreferences = settingsStore.tablePreferences;
  const globalFilter = tablePreferences.getSearchText(stateKey);
  const pageSize = tablePreferences.getPageSize(stateKey, defaultPageSize) ?? 20;
  const pageIndex = tablePreferences.getCurrentPage(stateKey) ?? 0;
  const sorting = tablePreferences.getSorting(stateKey, defaultSorting) ?? [];

  const pagination: MRT_PaginationState = { pageIndex, pageSize };

  const columns = useMemo(() => _.compact(getColumns()), [stateKey, localizationService.currentLocale]);

  function onGlobalFilterChange(updater: MRT_Updater<string>) {
    const newValue = updater instanceof Function ? updater(globalFilter ?? '') : updater;
    void tablePreferences.saveSearchText(stateKey, newValue);
  }

  function onPaginationChange(updater: MRT_Updater<MRT_PaginationState>) {
    const newValue = updater instanceof Function ? updater(pagination) : updater;

    if (newValue.pageIndex !== pagination.pageIndex) {
      tablePreferences.saveCurrentPage(stateKey, newValue.pageIndex);
    }

    if (newValue.pageSize !== pagination.pageSize) {
      void tablePreferences.savePageSize(stateKey, newValue.pageSize);
    }
  }

  function onSortingChange(updater: MRT_Updater<MRT_SortingState>) {
    const newValue = updater instanceof Function ? updater(sorting) : updater;
    void tablePreferences.saveSorting(stateKey, newValue);
  }

  const rowSelection: MRT_RowSelectionState =
    selectionOptions?.selectedRowIds.reduce((ids, id) => {
      ids[id] = true;
      return ids;
    }, {}) ?? {};

  function onSelectionChange(updater: MRT_Updater<MRT_RowSelectionState>) {
    const selection = updater instanceof Function ? updater(rowSelection) : updater;
    // Though the object is only supposed to contain keys with value "true", we still filter.
    const selectedRowIds = Object.keys(selection).filter((key) => selection[key]);
    selectionOptions?.onSelectionChanged(selectedRowIds);
  }

  const renderTitle: (table: MRT_TableInstance<TData>) => ReactNode =
    typeof title === 'string'
      ? () => (
          <Typography sx={{ flex: 1 }} variant="h6">
            {title}
          </Typography>
        )
      : title;

  return useMaterialReactTable({
    columns,
    data,
    renderTopToolbar: ({ table }) => (
      <Stack direction="row" spacing={1} padding={1} alignItems="center">
        <Box sx={{ flex: 1 }}>{renderTitle(table)}</Box>
        {!disableGlobalFilter && <MRT_GlobalFilterTextField table={table} />}
        {getTopActionButtons != null && (
          <Stack direction="row">
            {_.compact(getTopActionButtons({ table }))
              .filter((info) => info.isHidden !== true)
              .map((info, i) => (
                <Tooltip key={`top-action-${i}`} title={info.tooltip}>
                  {/* Tooltip doesn't appear if immediate child is disabled. */}
                  <Box>
                    <IconButton sx={{ ml: info.ml, mr: info.mr }} disabled={info.isDisabled} onClick={info.onClick}>
                      {info.icon()}
                    </IconButton>
                  </Box>
                </Tooltip>
              ))}
          </Stack>
        )}
        {renderCustomTopActions?.({ table })}
      </Stack>
    ),
    renderRowActionMenuItems:
      getRowActionMenuItems != null
        ? ({ row, closeMenu }) =>
            _.compact(getRowActionMenuItems({ row }))
              .filter((info) => info.isHidden !== true)
              .map((info, i) => (
                <MenuItem
                  key={`row-menu-item-${i}`}
                  disabled={info.isDisabled === true}
                  onClick={withCloseMenu(info.onClick, closeMenu)}
                >
                  {info.icon && <ListItemIcon>{info.icon()}</ListItemIcon>}
                  <ListItemText primary={info.title} />
                  {info.endAdornment && <ListItemIcon>{info.endAdornment()}</ListItemIcon>}
                </MenuItem>
              ))
        : undefined,
    renderRowActions:
      getRowActionButtons != null
        ? (props) => (
            <Stack direction="row" spacing={1}>
              {_.compact(getRowActionButtons(props))
                .filter((info) => info.isHidden !== true)
                .map((info, i) => (
                  <Tooltip key={`row-action-${i}`} title={info.tooltip}>
                    {/* Tooltip doesn't appear if immediate child is disabled. */}
                    <Box>
                      <IconButton sx={{ ml: info.ml, mr: info.mr }} disabled={info.isDisabled} onClick={info.onClick}>
                        {info.icon()}
                      </IconButton>
                    </Box>
                  </Tooltip>
                ))}
            </Stack>
          )
        : undefined,
    enablePagination: !disablePagination,
    enableGlobalFilter: !disableGlobalFilter,
    enableRowSelection: selectionOptions != null,
    getRowId: selectionOptions?.getRowId,
    onRowSelectionChange: selectionOptions != null ? onSelectionChange : undefined,
    enableMultiRowSelection: selectionOptions?.canMultiSelect ?? false,
    enableExpanding: getSubRows != null,
    getSubRows: getSubRows,
    // Options below are fixed for any of our tables.
    defaultColumn: {
      enableColumnActions: true,
      sortingFn: 'localeCompareFn',
      // STU-757: Doesn't seem to work.
      filterFn: 'localeContainsFn'
    },
    enableRowActions: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: true,
    enableHiding: false,
    enableFilterMatchHighlighting: false,
    positionActionsColumn: 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: ''
      },
      'mrt-row-select': {
        header: ''
      }
    },
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      columnPinning: {
        right: ['mrt-row-actions']
      }
    },
    onGlobalFilterChange,
    onPaginationChange,
    onSortingChange,
    localization: localizationService.currentLocale == 'fr' ? MRT_Localization_FR : undefined,
    state: {
      globalFilter,
      pagination,
      sorting,
      rowSelection
    },
    // STU-758: This is a temporary fix until we find why the table returns to index 0 after trying
    //          to move to the next page.
    autoResetPageIndex: false,
    muiTableBodyRowProps: (row) => ({
      ...rowProps?.(row),
      sx: {
        backgroundColor: row.row.depth > 0 ? blue[50] : undefined,
        // STU-759: Doesn't work, also tried '& .MuiTableRow-hover:hover' and '&:hover' (and App.css).
        //          (and !important).
        '&.MuiTableRow-hover:hover': {
          backgroundColor: grey[100]
        }
      }
    }),
    muiTableBodyCellProps: {
      sx: {
        fontSize: (theme) => (fontSize === 'small' ? theme.typography.body2.fontSize : theme.typography.body1.fontSize),
        fontWeight: (theme) =>
          fontSize === 'small' ? theme.typography.body2.fontWeight : theme.typography.body1.fontWeight
      }
    },
    muiTablePaperProps: {
      sx: {
        mt: 1
      },
      elevation: 0
    },
    sortingFns: {
      localeCompareFn: (row1, row2, columnId) =>
        toString(row1.getValue(columnId)).localeCompare(
          toString(row2.getValue(columnId)),
          localizationService.currentLocale,
          { sensitivity: 'base' }
        )
    },
    filterFns: {
      localeContainsFn: (row, columnId, filterValue) =>
        toString(row.getValue(columnId))
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .toLocaleLowerCase(localizationService.currentLocale)
          .includes(
            toString(filterValue)
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, '')
              .toLocaleLowerCase(localizationService.currentLocale)
          )
    },
    globalFilterFn: 'localeContainsFn'
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toString(a: any) {
  if (typeof a === 'number') {
    if (isNaN(a) || a === Infinity || a === -Infinity) {
      return '';
    }
    return String(a);
  }

  if (typeof a === 'string') {
    return a;
  }

  return '';
}
